import React, { Component } from 'react'
import Slider from 'react-slick'
import Img from 'gatsby-image'

import '../../../node_modules/slick-carousel/slick/slick.css'
import './gallery.css'

export default class Gallery extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.state = {
      nav1: null,
      nav2: null,
      currentSlide: 1,
    }
  }
  next() {
    this.slider1.slickNext()
  }
  previous() {
    this.slider1.slickPrev()
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const { data } = this.props
    const settingsbig = {
      dots: false,
      infinite: true,
      speed: 0,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipe: true,
      className: 'sliderbig sliderdestkop',
      accessibility: true,
      useCSS: false,
      useTransform: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            className: 'sliderbig slidermobile',
          },
        },
      ],
    }

    const settingsthumb = {
      slidesToShow: 0,
      slidesToScroll: 0,
      infinite: false,
      arrows: false,
      className: 'sliderthumb',
      swipeToSlide: false,
    }

    return (
      <div className="pagegallery">
        <Slider
          {...settingsbig}
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          afterChange={currentSlide => {
            this.setState({ currentSlide: currentSlide + 1 })
          }}
        >
          {data.markdownRemark.frontmatter.images.map(img => (
            <Img
              loading="eager"
              className="sliderphotobig"
              imgStyle={{ objectFit: 'contain' }}
              key={img.id}
              fluid={img.photobig.fluid}
            />
          ))}
        </Slider>
        <div className="slide-controls">
          <div
            className="slick-arrow slick-prev"
            onClick={this.previous}
            onKeyDown={this.previous}
            role="button"
            tabIndex={0}
          >
            Previous
          </div>
          <div className="slide-counter">
            <div className="counternumber">{this.state.currentSlide}</div>
            <div className="counternumber">/</div>
            <div className="counternumber">
              {data.markdownRemark.frontmatter.images.length}
            </div>
          </div>
          <div
            className="slick-arrow slick-next"
            onClick={this.next}
            onKeyDown={this.next}
            role="button"
            tabIndex={0}
          >
            Next
          </div>
        </div>
        <Slider
          {...settingsthumb}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          focusOnSelect={true}
        >
          {data.markdownRemark.frontmatter.images.map(img => (
            <img
              key={img.id}
              className="sliderphotothumb"
              width={img.photothumb.resize.width}
              height={img.photothumb.resize.height}
              alt="Thumbnail Navigation"
              src={img.photothumb.resize.src}
            ></img>
          ))}
        </Slider>
      </div>
    )
  }
}
