import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import Content from '../components/content'
import Sidebar from '../components/Sidebar'
import Gallery from '../components/gallery/gallery'
import GalleryMeta from '../components/gallery/gallerymeta'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark

  return (
    <>
      <Helmet
        title={`${frontmatter.title} - ${data.site.siteMetadata.title}`}
        bodyAttributes={{
          class: 'page',
        }}
        defer={false}
      >
        <meta property="og:title" content={frontmatter.title} />
        <meta
          property="og:image"
          content={`${data.site.siteMetadata.siteUrl}${frontmatter.featured.photoog.resize.src}`}
        />
        <meta
          property="og:image:width"
          content={frontmatter.featured.photoog.resize.width}
        />
        <meta
          property="og:image:height"
          content={frontmatter.featured.photoog.resize.height}
        />
      </Helmet>
      <Layout>
        <Sidebar />
        <Content>
          <Gallery data={data} />
          <GalleryMeta data={data} />
        </Content>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query projetsquery($path: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        category
        title
        date
        idu
        desc
        featured {
          photoog: childImageSharp {
            resize(width: 1200) {
              src
              width
              height
            }
          }
        }
        images {
          id
          relativePath
          photobig: childImageSharp {
            fluid(maxHeight: 920, quality: 86) {
              ...GatsbyImageSharpFluid
            }
          }
          photothumb: childImageSharp {
            resize(height: 30, width: 30, cropFocus: ENTROPY, quality: 20) {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`
