import React, { Component } from 'react'
import GalleryMetaCSS from './gallerymeta.module.css'
import Popup from 'reactjs-popup'

export default class GalleryMeta extends Component {
  render() {
    const { data } = this.props

    return (
      <div
        className={`${GalleryMetaCSS.metabox} 
        ${data.markdownRemark.frontmatter.category}`}
      >
        <h1 className={`${GalleryMetaCSS.title} galdesctitle`}>
          {data.markdownRemark.frontmatter.title}
        </h1>
        <div className={`${GalleryMetaCSS.hGalBar} galbar`} />

        {data.markdownRemark.frontmatter.desc !== null && (
          <Popup
            modal
            overlayStyle={{
              backgroundColor: 'rgba(255, 255, 255, 0)',
            }}
            className="galinfo"
            trigger={
              <div>
                <div className={`${GalleryMetaCSS.vGalBar} galbar`} />
                <div className={GalleryMetaCSS.trinfos}>infos</div>
              </div>
            }
          >
            {close => (
              <>
                <div
                  className={GalleryMetaCSS.close}
                  onClick={close}
                  onKeyDown={close}
                  role="button"
                  tabIndex={0}
                  aria-label="Close"
                />
                <div
                  className={GalleryMetaCSS.texte}
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
              </>
            )}
          </Popup>
        )}
      </div>
    )
  }
}
